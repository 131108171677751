<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
              <b-form  @submit.prevent="handleSubmit(send)" >
                <b-row>
                  <b-col lg="10" sm="12">
                    <ValidationProvider name="Circle Area Name (En)" vid="circle_area_name" rules="required">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="circle_area_name"
                          slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('irrigation_config.circle_area_name')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                            id="circle_area_name"
                            v-model="message"
                            :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <div class="row">
                  <div class="col-sm-3"></div>
                  <div class="col text-right">
                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                    &nbsp;
                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                  </div>
                </div>
              </b-form>
            </ValidationObserver>
          </template>
          <pre>{{ messageList }}</pre>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
// import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
// import { circleAreaStore, circleAreaUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      message: '',
      messageList: []
    }
  },
  created () {},
  mounted () {
    core.index()
  },
  methods: {
    send () {
      // this.$socket.emit('send-notification', { message: this.message })
      this.messageList.push({ message: this.message })
      this.message = ''
    }
  }
}
</script>
